<template>
  <div>
    <p class='text-wrap'>
      <span class='orange--text text--lighten-1'>Délais :</span>
      <br>
      La durée de création d’un produit débute à 5 jours mais elle dépend aussi de votre temps de réponse sur les propositions de maquettes.
    </p>

    <v-img :src='require(`@/assets/infos/order_timeline.png`)' />

    <p class='text-wrap'>
      <span class='orange--text text--lighten-1'>Paiement :</span>
      <br>
      Le règlement de votre commande se fait après votre validation du bon à tirer comprenant la maquette finale, par carte bancaire sur notre site
      via le site sécurisé de la Caisse d’Épargne.
    </p>

    <p class='text-wrap'>
      <span class='orange--text text--lighten-1'>Livraison :</span>
      <br>
      Lorsque votre commande sera prête vous recevrez un e-mail vous informant de son expédition.
      Votre commande sera livrée par les services de la Poste, comptez 2 jours ouvrés.
      Les frais d’expédition sont de 8€.
      <br>
      Vous pouvez également venir chercher votre commande à l’atelier de création, au
      <em>45 rue de l’hôpital militaire à Lille.</em>
    </p>

    <v-row class='mt-5'>
      <v-spacer />
      <v-col cols='2'>
        <v-img :src='require(`@/assets/infos/paper_plane.png`)' />
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
  export default { name: 'PaymentShipping' }
</script>
