// Import Vue instance
import Vue from 'vue';

// Return Number or Float into European currency
Vue.filter('currency', function (value) {
  if (!value) return '';

  return value.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR'}).replace(',', '.');
});

// Return Number or Float into European 'Flat' currency
Vue.filter('currencyFlat', function (value) {
  if (!value) return '';

  return Math.round(value).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 });
});

// Return Number or Float into European currency, returns 0 if no value
Vue.filter('currencyDefault', function (value) {
  if (!value) return '0 €';

  return value.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR'}).replace(',', '.');
});

// Return Number or Float into European 'Flat' currency, returns 0 if no value
Vue.filter('currencyFlatDefault', function (value) {
  if (!value) return '0 €';

  return Math.round(value).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 });
});

// Return string capitalized
Vue.filter('capitalize', function (value) {
  if (!value) return '';

  const words = value.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
});

// Return rounded integer value
Vue.filter('rounded', function (value) {
  if (!value) return 0;

  return Math.round(value * 100) / 100;
});

// Return Humanized siret (31360604800160 => 313 606 048 00160)
Vue.filter('humanizedSiret', function(value) {
  if(!value) return '';

  value = value.toString();
  let siren = value.substr(0, 9);
  const key = value.slice(9);

  siren = siren.replace(/(.{3})/g,'$1 ');

  return `${siren + key}`;
});
