<template>
  <v-row class='ma-0 pa-0 mt-10 d-none d-md-inline'>
    <v-spacer />
    <v-col cols='12'
           md='11'>
      <v-breadcrumbs :items='items'
                     divider='|'>
        <template v-slot:item='{ item }'>
          <v-breadcrumbs-item :to='item.to'>
            <span class='ubn-color'
                  v-if='item === items.at(-1)'>
              {{ item.text | capitalize }}
            </span>
            <span class='black--text'
                  v-else>
              {{ item.text | capitalize }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Breadcrumbs',
    props: { items: { type: Array, default: () => [] } }
  }
</script>
