// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        // primary: 'red'
        // secondary: colors.red.lighten4, // #FFCDD2
        // accent: colors.indigo.base, // #3F51B5
      },
    },
  },
};

export default new Vuetify(opts);
