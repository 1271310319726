<template>
  <v-card>
    <v-toolbar color='purple lighten-1'
               dense
               dark>
      <v-row>
        <v-col>
          {{ customerRequest.first_name }} {{ customerRequest.last_name }}
        </v-col>
        <v-spacer />
        <v-col class='d-flex justify-end'
               v-if='customerRequest.state === `pending`'>
          <v-btn class='green lighten-1 white--text mr-3'
                 icon
                 @click='updateState(`answered`)'
                 small>
            <v-icon>mdi-check-bold</v-icon>
          </v-btn>
          <v-btn class='red lighten-1 white--text'
                 icon
                 @click='updateState(`deleted`)'
                 small>
            <v-icon>mdi-close-octagon-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-card-text>
      <!-- Email && Phone -->
      <v-row class='mt-4'>
        <v-col>
          <v-text-field v-model='customerRequest.mail'
                        disabled
                        outlined
                        label='Email' />
        </v-col>
        <v-col>
          <v-text-field v-model='customerRequest.phone'
                        disabled
                        outlined
                        label='Téléphone' />
        </v-col>
      </v-row>
      <!-- Request type -->
      <v-row>
        <v-col>
          <v-text-field :value='parseRequestType'
                        disabled
                        outlined
                        label='Type de demande' />
        </v-col>
      </v-row>
      <!-- Content -->
      <v-row>
        <v-col>
          <v-textarea v-model='customerRequest.content'
                      counter='5000'
                      disabled
                      outlined
                      rows='4'
                      label='Demande client' />
        </v-col>
      </v-row>
      <!-- Images -->
      <p class='text-h5'
         v-if='customerRequest.images_urls > 0'>
        Images
      </p>
      <v-row>
        <v-col v-for='(url, index) in customerRequest.images_urls'
               :key='index'>
          <a :href='url'
             target='_blank'>
            <v-img :src='url'
                   max-width='500'
                   contain
                   aspect-ratio='1' />
          </a>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'CustomerRequest',
    props: {
      customerRequest: { type: Object, default: () => {} }
    },
    computed: {
      parseRequestType() {
        return this.requestTypes.find(d => d.value === this.customerRequest.request_type).translate;
      }
    },
    data: () => ({
      requestTypes: [
        { translate: 'personalisation d\'une illustration', value: 'personalization' },
        { translate: 'demande d\'une illustration sur mesure', value: 'custom_made'  },
        { translate: 'demande d\'un devis', value: 'quote'  },
        { translate: 'autre', value: 'other' }
      ]
    }),
    methods: {
      updateState(state) {
        const params = { customer_request: { state: state } };

        this.axios.put(`/v1/admin/customer_requests/${this.customerRequest.id}`, params)
                  .then(() => {
                    this.$root.$emit('requestSuccess', this.customerRequest.id, state);
                  })
                  .catch(() => {
                    this.$root.$emit('requestError');
                  });
      }
    }
  }
</script>
