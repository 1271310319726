<template>
  <v-container>
    <!-- Title and btn "new" -->
    <v-row class='mb-5 mt-5'>
      <v-col cols='3'>
        <span class='text-h4'>Catégories</span>
      </v-col>

      <v-spacer />

      <v-col cols='2'>
        <v-btn color='green darken-1'
               class='white--text'
               elevation='0'
               small
               @click='dialog = !dialog'>
          Nouvelle catégorie
        </v-btn>
      </v-col>
    </v-row>

    <!-- Expansions panels for categories -->
    <CategoriesPanels :categories='categories'
                      :all-categories='allCategories' />

    <!-- Snackbar success -->
    <v-snackbar v-model='snackbarSuccess'
                :timeout='2000'
                right
                color='deep-purple accent-4'
                elevation='24'>
      Catégorie mise à jour avec succès !
    </v-snackbar>

    <!-- Snackbar Error -->
    <v-snackbar v-model='snackbarError'
                :timeout='2000'
                right
                color='red accent-4'
                elevation='24'>
      Une erreur est survenue
    </v-snackbar>

    <!-- Modal new category -->
    <v-dialog transition='dialog-bottom-transition'
              v-model='dialog'
              max-width='900'>
      <v-card>
        <v-toolbar color='green lighten-1'
                   dense
                   dark>
          Nouvelle catégorie
        </v-toolbar>
        <v-card-text>
          <!-- title & state & parent_id -->
          <v-row>
            <v-col cols='4'>
              <v-text-field v-model='newCategory.title'
                            label='Titre' />
            </v-col>

            <v-col cols='4'>
              <v-select :items='allCategories'
                        v-model='newCategory.parent_id'
                        item-value='id'
                        hide-details='auto'
                        label='Attachée à'>
                <template v-slot:item='{ item }'>
                  <span v-for='n in item.depth'
                        :key='`${item.id}-${n}`'>
                    &nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;
                  </span>
                  {{ item.title }}
                </template>
                <template v-slot:selection='{ item }'>
                  {{ item.title }}
                </template>
              </v-select>
            </v-col>

            <v-col cols='4'>
              <v-select :items='[`enabled`, `disabled`, `pendingss`]'
                        v-model='newCategory.state'
                        hide-details='auto'
                        label='État' />
            </v-col>
          </v-row>
          <!-- description & large_description -->
          <v-row>
            <v-col cols='5'>
              <v-textarea v-model='newCategory.description'
                          label='Description' />
            </v-col>

            <v-spacer />

            <v-col cols='5'>
              <v-textarea v-model='newCategory.large_description'
                          label='Longue description' />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class='justify-end'>
          <v-btn text
                 @click='createCategory'>
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import CategoriesPanels from './CategoriesPanels';

  export default {
    name: 'AdminCategories',
    components: { CategoriesPanels },
    data: () => ({
      categories: [],
      allCategories: [{ id: null }],
      newCategory: {
        title: '',
        state: 'enabled',
        parent_id: undefined,
        description: '',
        large_description: ''
      },
      snackbarSuccess: false,
      snackbarError: false,
      dialog: false
    }),
    async mounted() {
      this.$root.$on('requestSuccess', () => this.snackbarSuccess = true);
      this.$root.$on('requestError', () => this.snackbarError = true);

      await this.fetchCategories();
      this.setAllCategories(this.categories, 0);
    },
    methods: {
      fetchCategories() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/categories')
                    .then(response => {
                      this.categories = response.data;
                      resolve();
                    });
        })
      },
      setAllCategories(categories, depth) {
        categories.map(category => {
          const data = { id: category.data.id, title: category.data.attributes.title, depth: depth };
          this.allCategories.push(data);

          this.setAllCategories(category.data.attributes.children, depth + 1);
        });
      },
      createCategory() {
        const params = { category: this.newCategory }

        this.axios.post('/v1/admin/categories/', params)
                  .then(async () => {
                    this.categories = [];
                    this.allCategories = [{ id: null }];
                    this.newCategory = {
                      title: '',
                      state: 'enabled',
                      parent_id: undefined,
                      description: '',
                      large_description: ''
                    };

                    await this.fetchCategories();
                    this.setAllCategories(this.categories, 0);

                    this.snackbarSuccess = true;
                    this.dialog = false
                  })
                  .catch(() => this.snackbarError = true);
      }
    }
  }
</script>
