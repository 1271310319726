import Vue from 'vue';
import VueLuxon from 'vue-luxon';

// Vue.use(VueLuxon);

Vue.use(VueLuxon, {
  templates: {},
  input: {
    zone: 'utc',
    format: 'iso'
  },
  output: {
    zone: 'local',
    format: 'dd LLLL yyyy',
    locale: 'fr',
    relative: {
      round: true,
      unit: null
    }
  }
});
