<template>
  <div>
    <p class='text-wrap'>
      Les prix sont dégressifs (hors options) en €, TTC. Les prix sont communs
      à tous les modèles ainsi qu’à toutes les enveloppes quelque soit leur
      format. Ils comprennent le maquettisme, l’assistance, l’impression et la retouche photo.
    </p>

    <p>
      <span class='orange--text text--lighten-1'>Les options :</span>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class='text-wrap'>
              Cartes de remerciements : supplément de 0.20€ / exemplaire pour l’ajout d’une photo, retouches incluses.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class='text-wrap'>
              Coins arrondis 0,10€ par exemplaire.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class='text-wrap'>
              Pour une création sur-mesure, comptez 25€ en supplément des prix affichés ci-dessous,
              quelque soit le nombre de modèles.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </p>

    <p class='text-wrap'>
      Les frais de livraison sont de 8€ (France métropolitaine - supplément pour les livraisons à l’étranger)
    </p>
  </div>
</template>

<script>
  export default { name: 'Prices' }
</script>
