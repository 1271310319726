<template>
  <div>
    <h2>Commandes</h2>

    <v-row>
      <v-spacer />

      <v-col cols='10'>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Date</th>
                <th>Montant</th>
                <th>Paiement</th>
                <th>Statut</th>
                <th>Informations client</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-for='item in orders'
                  :key='item.data.id'>
                <td title='Date de création de la commande'
                    class='font-weight-bold'>
                  {{ item.data.attributes.created_at | luxon }}
                </td>
                <td title='Montant de la commande'
                    class='font-weight-bold green--text text--darken-1'>
                  {{ item.data.attributes.total_amount | currency }}
                </td>
                <td title='Statut de paiement'>
                  <span v-if='item.data.attributes.payment_state === `paid`'>
                    <v-btn icon
                           class='font-weight-bold green darken-2 white--text'>
                      <font-awesome-icon icon='check' />
                    </v-btn>
                  </span>
                  <span v-else>
                    <v-btn icon
                           class='font-weight-bold red darken-2 white--text'>
                      <font-awesome-icon :icon='[`far`, `times-circle`]' />
                    </v-btn>
                  </span>
                </td>
                <td title='Statut de la commande'>
                  <v-chip :color='states.filter(s => s.value === item.data.attributes.state)[0].color'
                          dark>
                    {{ item.data.attributes.translated_state }}
                  </v-chip>
                </td>
                <td title='Informations client'>
                  {{ item.data.attributes.firstname }} {{ item.data.attributes.lastname }} - {{ item.data.attributes.email }}
                </td>
                <td title='Accès à la commande'>
                  <router-link :to='`/admin/orders/` + item.data.id'
                               class='text-decoration-none'>
                    <v-btn icon
                           class='font-weight-bold blue lighten-2 white--text'>
                      <font-awesome-icon icon='arrow-right' />
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-spacer />
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'AdminOrders',
    data: () => ({
      orders: [],
      states: [
        { value: 'pending', color: 'purple' },
        { value: 'sent', color: 'teal' },
        { value: 'canceled', color: 'red' },
        { value: 'wip', color: 'blue' },
        { value: 'aborted', color: 'red' },
        { value: 'completed', color: 'green' }
      ]
    }),
    async mounted() {
      await this.fetchOrders();
    },
    methods: {
      fetchOrders() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/orders/')
                    .then(response => {
                      this.orders = response.data;

                      resolve();
                    });
        });
      }
    }
  }
</script>
