<template>
  <v-container>
    <!-- Description -->
    <div class='text--body-1 pl-10 ml-10 mb-10 simple-format'>
      {{ category.data.attributes.description }}
    </div>

    <!-- Skeleton loader -->
    <v-row v-if='loading'>
      <v-col v-for='n in 12'
             :key='n'
             cols='4'
             class='pl-10 pr-10 pt-6 pb-6'>
        <v-skeleton-loader :boilerplate='false'
                           elevation='2'
                           type='card' />
      </v-col>
    </v-row>

    <!-- products -->
    <v-row>
      <v-col v-for='product in products'
             :key='product.data.id'
             cols='12'
             md='4'
             class='pl-10 pr-10 pt-6 pb-6'>
        <router-link :to='`/products/${product.data.attributes.product_id}`'
                     class='text-decoration-none'>
          <v-img :lazy-src='product.data.attributes.lazy_image_url'
                 :src='product.data.attributes.image_url'
                 aspect-ratio='1'
                 contain
                 class='img-zoom-in' />
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Categories',
    data: () => ({
      category: { data: { attributes: {} } },
      products: [],
      loading: true,
      position: 0,
      loadAmount: 12,
      endOfData: false
    }),
    watch: {
      $route() { this.resetData(); }
    },
    async mounted() {
      await this.$vuetify.goTo(0, { duration: 300, offset: 0, easing: 'linear' })
      this.scroll();
      await this.fetchCategory();
      this.setBreadcrumbs();
      await this.fetchProducts();
    },
    methods: {
      fetchCategory() {
        return new Promise(resolve => {
          this.axios.get(`/v1/categories/${this.$route.params.id}`)
                    .then(response => {
                      this.category = response.data;
                      resolve();
                    });
        })
      },
      setBreadcrumbs() {
        const items = [];
        items.push({ text: this.category.data.attributes.title, to: '' });

        if(this.category.data.attributes.parents) {
          this.category.data.attributes.parents.map(parent => {
            items.push({ text: parent.title, to: '' })
          });
        }

        this.$root.$emit('updateBreadcrumbs', items.reverse());
      },
      fetchProducts() {
        return new Promise(resolve => {
          this.loading = true;
          const params = { position: this.position, load: this.loadAmount }

          this.axios.get(`/v1/category_products/${this.$route.params.id}`, { params: params })
                    .then(response => {
                      response.data.map(d => this.products.push(d));

                      if(response.data.length < 12) { this.endOfData = true; }
                      if(response.data.length > 0) { this.position = this.products.at(-1).data.attributes.position + 1; }

                      this.loading = false;

                      resolve();
                    });
        })
      },
      scroll() {
        window.onscroll = () => {
          if (this.loading || this.endOfData || this.$route.name !== 'categories') { return; }

          const base = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight
          const bottom = (document.documentElement.offsetHeight / 20) * 19

          if (base > bottom) { this.fetchProducts(); }
        }
      },
      async resetData() {
        if(this.$route.name !== 'categories') { return; }

        this.products = [];
        this.position = 0;
        this.endOfData = false;

        await this.fetchCategory();
        this.setBreadcrumbs()
        await this.fetchProducts();
      }
    }
  }
</script>

<style scoped>
  .height-auto {
    width: auto;
    height: 0;
    padding-bottom: 100%;
  }

  .img-zoom-in {
    transition: 150ms transform ease-in-out;
  }

  .img-zoom-in:hover {
    transform: scale(1.03);
  }
</style>
