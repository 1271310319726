<template>
  <v-container>
    <v-row>
      <v-dialog v-model='dialog'
                fullscreen
                persistent
                hide-overlay
                transition='dialog-bottom-transition'>
        <v-card>
          <v-toolbar dark
                     color='primary'>
            <v-toolbar-title>Paiement</v-toolbar-title>
          </v-toolbar>

          <v-card-text class='d-flex align-center justify-center'
                       style='height: 80vh;'>
            <v-progress-circular indeterminate
                                 :size='70'
                                 :width='7'
                                 v-if='loading'
                                 :active='loading' />

            <v-card v-if='!loading'>
              <v-card-title class='red lighten-3 white--text font-weight-bold'>
                Erreur
              </v-card-title>
              <v-card-text class='text-center text-body-1 mt-5'>
                Une erreur est survenue lors du paiement de {{ session.amount / 100 | currencyDefault }}
              </v-card-text>
              <v-card-actions>
                <v-container>
                  <v-row class='ma-4 text-caption'>
                    Vous allez être redirigé automatiquement vers le panier dans quelques secondes.
                  </v-row>

                  <v-row class='ma-4'>
                    Si rien ne se passe, cliquez&nbsp;
                    <router-link to='/carts'>
                      ici
                    </router-link>
                    .
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'CheckoutFailed',
    data: () => ({
      loading: true,
      dialog: false,
      session: {}
    }),
    mounted() {
      if(!localStorage.getItem('cart_secure_session')) window.location = '/';

      this.dialog = true;

      this.axios.put(`v1/checkout/carts/${localStorage.getItem('cart_secure_session')}`)
                .then(response => {
                  this.session = response.data;
                  this.loading = false;

                  setTimeout(() => { window.location = '/carts'; }, 5000);
                });
    }
  }
</script>
