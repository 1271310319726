<template>
  <v-container fluid>
    <v-row align='center'>
      <!-- Windows -->
      <v-col cols='12'
             md='9'>
        <v-window v-model='window'
                  class='elevation-1'
                  vertical>
          <WindowItems :items='windows' />
        </v-window>
      </v-col>

      <!-- buttons -->
      <v-col cols='3'
             class='d-none d-md-flex'>
        <v-item-group v-model='window'
                      class='mr-6 text-truncate'
                      style='position: fixed; bottom: 200px; z-index: 10;'
                      mandatory>
          <v-item v-for='(window, index) in windows'
                  class='mt-3'
                  :key='index'
                  v-slot='{ active, toggle }'>
            <div>
              <v-tooltip bottom
                         open-delay='300'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn :class='{ "ubn-color": active }'
                         text
                         small
                         v-bind='attrs'
                         v-on='on'
                         @click='toggle'>
                    <v-icon>mdi-record</v-icon>
                    {{ window.name }}
                  </v-btn>
                </template>
                <span>{{ window.name }}</span>
              </v-tooltip>
            </div>
          </v-item>
        </v-item-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import WindowItems from './WindowItems';

  export default {
    name: 'Infos',
    components: { WindowItems },
    data: () => ({
      window: 0,
      windows: [
        { name: 'Comment passer une commande de faire-part', href: 'how-to-order' },
        { name: 'Bonne nouvelle!', href: 'good-news' },
        { name: 'Tarifs', href: 'prices' },
        { name: 'Paiement, délais et livraison', href: 'payment-shipping' },
        { name: 'Contact', href: 'contact' },
        { name: 'CGV', href: 'cgv' }
      ]
    }),
    watch: {
      // eslint-disable-next-line no-unused-vars
      $route () { this.setWindow(); this.setBreadcrumbs(); },
      window () { this.setParams(); this.setBreadcrumbs(); }
    },
    mounted() { this.setWindow(); this.setBreadcrumbs(); },
    methods: {
      setWindow() {
        this.window = this.windows.findIndex(e => e.href === this.$route.params.type);
      },
      setParams() {
        const type = this.windows[this.window].href;

        history.pushState({}, null, `/infos/${type}`)
      },
      setBreadcrumbs() {
        const items = [
          { text: 'informations', to: '' },
          { text: this.windows[this.window].name, to: ''}
        ];

        this.$root.$emit('updateBreadcrumbs', items);
      }
    }
  }
</script>

<style>
  .v-btn {
    text-transform: capitalize !important;
  }

  p, li {
    font-size: 1rem !important;
  }

  /*.text-custom {*/
  /*  color: #d59f9f !important;*/
  /*}*/

  /*.v-btn.v-size--default, .v-btn.v-size--large {*/
  /*  font-size: .7rem;*/
  /*}*/
</style>
