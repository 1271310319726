<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols='6'
             md='3'>
        <a href='https://www.instagram.com/creation.unebonnenouvelle/?hl=fr'
           target='_blank'>
          <v-img :src='require(`@/assets/footer.png`)' />
        </a>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
  export default { name: 'Footer' }
</script>
