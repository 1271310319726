<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class='title'>
          Navigation
        </v-list-item-title>
        <!--<v-list-item-subtitle>-->
        <!--subtext-->
        <!--</v-list-item-subtitle>-->
      </v-list-item-content>
    </v-list-item>

    <v-list>
      <!-- Accueil -->
      <v-list-item>
        <v-list-item-icon>
          <font-awesome-icon :icon='[`fas`, `home`]' />
        </v-list-item-icon>
        <v-list-item-content>
          <router-link :to='{ name: `admin` }'
                       class='no-decoration'>
            <span>Accueil</span>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <!-- Orders -->
      <v-list-item>
        <v-list-item-icon>
          <font-awesome-icon :icon='[`far`, `file-alt`]' />
        </v-list-item-icon>
        <v-list-item-content>
          <router-link :to='{ name: `admin_orders` }'
                       class='no-decoration'>
            <div>
              <span>Commandes &nbsp;</span>
              <v-btn v-if='pendingOrders > 0'
                     class='green lighten-2 white--text'
                     icon
                     x-small>
                {{ pendingOrders }}
              </v-btn>
            </div>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <!-- Customer Request -->
      <v-list-item>
        <v-list-item-icon>
          <font-awesome-icon icon='exchange-alt' />
        </v-list-item-icon>
        <v-list-item-content>
          <router-link :to='{ name: `admin_customer_requests` }'
                       class='no-decoration'>
            <div>
              <span>Demandes clients &nbsp;</span>
              <v-btn v-if='pendingCustomerRequests > 0'
                     class='blue lighten-2 white--text'
                     icon
                     x-small>
                {{ pendingCustomerRequests }}
              </v-btn>
            </div>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <!-- Products -->
      <v-list-item>
        <v-list-item-icon>
          <font-awesome-icon :icon='[`fas`, `book`]' />
        </v-list-item-icon>
        <v-list-item-content>
          <router-link :to='{ name: `admin_products` }'
                       class='no-decoration'>
            <span>Modèles</span>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <!-- categories -->
      <v-list-item>
        <v-list-item-icon>
          <font-awesome-icon :icon='[`fas`, `pencil-alt`]' />
        </v-list-item-icon>
        <v-list-item-content>
          <router-link :to='{ name: `admin_categories` }'
                       class='no-decoration'>
            <span>Catégories</span>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <!-- livraisons -->
      <v-list-item>
        <v-list-item-icon>
          <font-awesome-icon :icon='[`fas`, `box`]' />
        </v-list-item-icon>
        <v-list-item-content>
          <router-link :to='{ name: `admin_shippings` }'
                       class='no-decoration'>
            <span>Livraisons</span>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <!-- Discounts -->
      <v-list-item>
        <v-list-item-icon>
          <font-awesome-icon :icon='[`fas`, `hand-holding-usd`]' />
        </v-list-item-icon>
        <v-list-item-content>
          <router-link :to='{ name: `admin_discounts` }'
                       class='no-decoration'>
            <span>Code promos</span>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <!-- Front page -->
      <v-list-item>
        <v-list-item-content>
          <router-link :to='{ name: `home` }'
                       target='_blank'
                       class='no-decoration d-flex justify-center'>
            <span>Accéder au site</span>
          </router-link>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  export default {
    name: 'NavBar',
    data: () => ({
      customerRequests: [],
      orders: []
    }),
    computed: {
      pendingCustomerRequests() {
        return this.customerRequests.filter(d => d.data.attributes.dismissed === false).length;
      },
      pendingOrders() {
        return this.orders.filter(d => d.data.attributes.state === 'pending').length;
      }
    },
    async mounted() {
      this.$root.$on('readCustomerRequest', () => this.fetchCustomerRequests());
      this.$root.$on('changeOrderState', () => this.fetchOrders());

      await this.fetchCustomerRequests();
      await this.fetchOrders();
    },
    methods: {
      fetchCustomerRequests() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/customer_requests')
                    .then(response => {
                      this.customerRequests = response.data;
                      resolve();
                    });
        });
      },
      fetchOrders() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/orders/')
                    .then(response => {
                      this.orders = response.data;

                      resolve();
                    });
        });
      }
    }
  }
</script>

<style scoped>
  .router-link-active {
    font-weight: 600 !important;
  }
</style>
