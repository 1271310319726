<template>
  <div>
    <h2>Code promos</h2>
  </div>
</template>

<script>
  export default {
    name: 'AdminDiscounts'
  }
</script>
