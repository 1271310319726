<template>
  <router-view />
</template>

<script>
  export default { name: 'App' };
</script>


<style lang='scss'>
  @font-face {
    font-family: 'louis-george-cafe';
    font-display: auto;
    unicode-range: U+000-5FF;
    src: url('./fonts/LouisGeorgeCafe/LouisGeorgeCafe.ttf') format('truetype');
  }

  #app {
    font-family: louis-george-cafe, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #363434;
  }

  .v-application .text-h6,
  .v-application .text-h5,
  .v-application .text-h4,
  .v-application .text-h3,
  .v-application .text-h2,
  .v-application .text-h1 {
    font-family: louis-george-cafe, sans-serif !important;
  }

  a.no-decoration {
    color: inherit !important; /* blue colors for links too */
    text-decoration: inherit !important; /* no underline */
  }

  .text-danger {
    color: #f64c4c !important;
    font-weight: bold;
  }

  .simple-format {
    white-space: pre-wrap;
  }

  .custom-btn {
    font-size: .875rem;
    font-weight: bolder;
  }

  .ubn-bg-color {
    //background-color: #f89489;
    background-color: #d59f9f;
  }

  .ubn-color {
    color: #d59f9f !important;
  }
</style>
