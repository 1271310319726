<template>
  <v-expansion-panels focusable
                      active-class='focused-category'>
    <v-expansion-panel v-for='category in categories'
                       :key='category.data.id'>
      <v-expansion-panel-header v-slot='{ open }'>
        <v-row no-gutters>
          <v-col cols='4'>
            {{ category.data.attributes.title }}
          </v-col>
          <v-col cols='8'
                 class='text--secondary'>
            <v-fade-transition leave-absolute>
              <span v-if='open'>Modifier la catégorie</span>
              <v-row v-else
                     no-gutters
                     style='width: 100%'>
                <v-col cols='6'>
                  {{ category.data.attributes.state }}
                </v-col>
              </v-row>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- title & state -->
        <v-row>
          <v-col cols='4'>
            <v-text-field v-model='category.data.attributes.title'
                          label='Titre' />
          </v-col>

          <v-col cols='4'>
            <v-select :items='allCategories'
                      v-model='category.data.attributes.parent_id'
                      item-value='id'
                      hide-details='auto'
                      label='Attachée à'>
              <template v-slot:item='{ item }'>
                <span v-for='n in item.depth'
                      :key='`${item.id}-${n}`'>
                  &nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;
                </span>
                {{ item.title }}
              </template>
              <template v-slot:selection='{ item }'>
                {{ item.title }}
              </template>
            </v-select>
          </v-col>

          <v-col cols='4'>
            <v-select :items='[`enabled`, `disabled`, `pending`]'
                      v-model='category.data.attributes.state'
                      hide-details='auto'
                      label='État' />
          </v-col>
        </v-row>
        <!-- description & large_description -->
        <v-row>
          <v-col cols='5'>
            <v-textarea v-model='category.data.attributes.description'
                        label='Description' />
          </v-col>

          <v-spacer />

          <v-col cols='5'>
            <v-textarea v-model='category.data.attributes.large_description'
                        label='Longue description' />
          </v-col>
        </v-row>
        <!-- submit btn -->
        <v-row>
          <v-spacer />
          <v-col cols='2'
                 class='mb-5'>
            <v-btn color='green darken-2 white--text'
                   @click='updateCategory(category.data)'>
              Sauvegarder
            </v-btn>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class='mt-5 pl-10 pa-3'>
          <CategoriesPanels :categories='category.data.attributes.children'
                            :all-categories='allCategories' />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import CategoriesPanels from './CategoriesPanels';

  export default {
    name: 'CategoriesPanels',
    components: { CategoriesPanels },
    props: {
      categories: { type: Array, default: () => [] },
      allCategories: { type: Array, default: () => [] }
    },
    methods: {
      updateCategory(item) {
        const params = {
          category: {
            title: item.attributes.title,
            state: item.attributes.state,
            description: item.attributes.description,
            large_description: item.attributes.large_description,
            parent_id: item.attributes.parent_id
          }
        }

        this.axios.put(`/v1/admin/categories/${item.id}`, params)
                  .then(() => this.$root.$emit('requestSuccess'))
                  .catch(() => this.$root.$emit('requestError'));
      }
    }
  }
</script>

<style lang='scss' scoped>
  .v-expansion-panel-header--active:first-child {
    background-color: #B39DDB !important;
    color: white !important;
  }

  .v-expansion-panel-header {
    background-color: #BBDEFB !important;
    color: black !important;
  }
</style>
