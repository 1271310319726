<template>
  <div>
    <p class='text-wrap'>
      Les prix sont en € TTC et comprennent le maquettisme, l’impression et la retouche photo.
      <br>
      Le paiement se fait par carte bancaire sur notre site, une fois le bon à tirer validé.
      <br>
      La validation du bon à tirer vaut pour acceptation de celui ci  et aucune modification
      ne pourra être apportée à la commande.
    </p>

    <p class='text-wrap'>
      En vertu de l'article <span class='text-caption'>L 121-20-2 du Code de la Consommation</span>,
      le droit de rétractation ne peut être exercé pour les produits personnalisés.
      <br>
      Les commandes ne peuvent donc être remplacées ou remboursés, sauf en cas des mentions suivantes.
      <br>
      En cas de non conformité du produit, (erreur de texte, erreur de photo de la part d’Une Bonne Nouvelle),
      le client dispose d’un droit de réclamation. Il en fera la demande par mail
      <a href='mailto:contact@une-bonne-nouvelle.fr'>contact@une-bonne-nouvelle.fr</a>

      <br>
      Il lui sera alors proposé un remplacement ou un remboursement si la demande est valable et justifiée.
      <br>
      Ne sera pas valable, toute réclamation portant sur l’orthographe des textes ajoutés
      par le client et/ou sur la qualité des photos.
      <br>
      Si nous constatons une erreur dans le texte que le client propose, ou que la
      qualité d’une photo ne convient pas à l’impression nous guiderons
      le client jusqu’à la meilleure qualité du produit.
      <br>
      Les couleurs des modèles présentés sur le site et des bons à tirer sont
      susceptibles d’être différentes entre l’affichage à l’écran et l’impression
      sur papier. C’est pourquoi ce critère ne peut être valable pour une réclamation.
      <br>
      En cas de remplacement de la commande, Une Bonne Nouvelle se réserve le droit
      de demander au client de retourner le produit non conforme.
    </p>

    <p class='text-wrap'>
      En payant sa commande le client accepte les conditions générales de vente.
    </p>

    <p class='text-wrap'>
      L’impression de la commande débutera après réception du paiement.
      <br>
      Les frais d’expédition sont à la charge du client et les délais de fabrication
      d’une commande ne comprennent pas les délais de livraison.
      <br>
      Une Bonne Nouvelle se réserve le droit d’annuler une commande, dans ce cas
      les frais de commande et de livraison seront totalement remboursés dans les
      plus brefs délais.
    </p>

    <p class='text-wrap'>
      Les modèles présentés sur ce site sont protégés et toute reproduction
      est strictement interdite.
    </p>

    <p class='text-wrap'>
      Une Bonne Nouvelle est un site de l’entreprise Origami gérée par Marie Riem,
      située au <span class='text-caption'>45 rue de l’Hôpital Militaire, 59800 Lille</span>
      <br>
      N° SIRET : <span class='text-caption'>{{ '83941337400019' | humanizedSiret }}</span>
    </p>
  </div>
</template>

<script>
  export default { name: 'TOS' }
</script>
