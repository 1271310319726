<template>
  <div>
    <p>
      Nous avons une bonne nouvelle! Nos cartes sont faites pour vous ... si vous aimez l’originalité et le travail bien fait : -)
      <br>
      <br>
      Les petits + :
    </p>

    <p>
      <span class='orange--text text--lighten-1'>La proximité</span>
      <br>
      Vous comptez sur nous, vous comptez pour nous !
      Nous travaillons avec passion, c’est pourquoi Une Bonne Nouvelle prend toutes vos demandes très au sérieux et notre graphiste,
      aux petits soins avec vous s’adaptera à tous vos souhaits.
    </p>

    <p>
      <span class='orange--text text--lighten-1'>Des prix qui ne cachent rien,</span>
      <br>
      ainsi, nos tarifs comprennent :
    </p>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class='text-wrap'>
            La réalisation de la
            <span class='orange--text text--lighten-1'>maquette</span>
            , c’est à dire la mise en page des textes, illustrations, photos sur le modèle choisi.
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class='text-wrap'>
            L’
            <span class='orange--text text--lighten-1'>impression</span>
            et le pliage (pour les cartes pliées).
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class='text-wrap'>
            Une
            <span class='orange--text text--lighten-1'>assistance sur mesure</span>
            chaque commande est traitée singulièrement, nous vous accompagnons dans vos choix et nous
            vérifions attentivement les textes et les photos; ainsi, si nous constatons une erreur dans le texte ou les informations que vous nous fournissez,
            nous vous suggérerons une correction. De même, si la qualité d’une photo ne convient pas à l’impression nous vous guiderons jusqu’à la
            meilleure qualité du produit.
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class='text-wrap'>
            La
            <span class='orange--text text--lighten-1'>retouche des photos</span>
            si elle est nécessaire et/ou demandée: le recadrage, la luminosité, les couleurs,
            la suppression d’éléments indésirables...
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-row class='mt-5'>
      <v-spacer />
      <v-col cols='2'>
        <v-img :src='require(`@/assets/infos/ok_hand.png`)' />
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
  export default { name: 'GoodNews' }
</script>
