<template>
  <div>
    <v-row>
      <v-col>
        <p class='text-center ubn-color text-h5'>
          Vous souhaitez une personnalisation, me demandez plus d'explications, ou simplement un conseil ?
          <br>
          Complétez le formulaire ci-dessous, je serais ravie d'échanger avec vous.
        </p>
      </v-col>
    </v-row>
    <!-- Form -->
    <v-row>
      <v-spacer />
      <v-col cols='10'>
        <v-form @submit.prevent='sendRequest'
                enctype='multipart/form-data'>
          <!-- First Name && Last Name-->
          <v-row>
            <v-col>
              <v-text-field v-model='form.firstName'
                            autofocus
                            required
                            :validate-on-blur='loading'
                            label='Prénom*' />
            </v-col>

            <v-col>
              <v-text-field v-model='form.lastName'
                            :validate-on-blur='loading'
                            required
                            label='Nom*' />
            </v-col>
          </v-row>
          <!-- Email -->
          <v-row>
            <v-col>
              <v-text-field v-model='form.email'
                            autofocus
                            required
                            :validate-on-blur='loading'
                            label='Email*' />
            </v-col>
          </v-row>
          <!-- phone -->
          <v-row>
            <v-col cols='12'>
              <v-text-field v-model='form.phone'
                            autofocus
                            required
                            :validate-on-blur='loading'
                            label='Téléphone*' />
            </v-col>
          </v-row>
          <!-- request type -->
          <v-row>
            <v-col>
              <v-select :items='requestTypes'
                        v-model='form.requestType'
                        required
                        item-text='translate'
                        item-value='value'
                        hide-details='auto'
                        label='Sélectionnez votre demande*' />
            </v-col>
          </v-row>
          <!-- Request -->
          <v-row>
            <v-col>
              <v-textarea v-model='form.content'
                          counter='5000'
                          required
                          rows='3'
                          label='Tapez votre demande*' />
            </v-col>
          </v-row>
          <!-- Files -->
          <v-row>
            <v-col>
              <p class='text-center ubn-color text-h6'>
                Vous pouvez envoyer des images
              </p>
            </v-col>
          </v-row>
          <v-row v-for='(n, index) in (form.files.length + 1)'
                 :key='n'>
            <v-col>
              <v-file-input v-model='form.files[index]'
                            @change='filterArray'
                            show-size
                            chips
                            accept='image/*' />
            </v-col>
          </v-row>

          <!-- BTN -->
          <v-row>
            <v-spacer />
            <v-col cols='4'
                   class='d-flex justify-center'>
              <v-btn x-large
                     color='#d59f9f'
                     class='mr-4 p white--text'
                     type='submit'>
                Envoyer
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-form>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- Snackbar success -->
    <v-snackbar v-model='snackbarSuccess'
                :timeout='5000'
                centered
                multi-line
                color='teal darken-1'
                elevation='24'>
      <p class='text-subtitle-1'>
        Votre demande a bien été prise en compte,
        <br>
        nous revenons vers vous dès que possible !
      </p>
    </v-snackbar>

    <!-- Snackbar Error -->
    <v-snackbar v-model='snackbarError'
                :timeout='-1'
                centered
                multi-line
                color='red lighten-2'
                elevation='24'>
      <p class='text-subtitle-1'>
        Une erreur est survenue, veuillez réessayer de poster votre demande ou nous envoyer un mail :
      </p>
      <p class='text-h6 blue--text text--darken-2'>
        contact@une-bonne-nouvelle.fr
      </p>
      <template v-slot:action='{ attrs }'>
        <v-btn color='indigo'
               small
               v-bind='attrs'
               @click='snackbarError = false'>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'Contact',
    data: () => ({
      loading: false,
      snackbarSuccess: false,
      snackbarError: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        content: '',
        requestType: 'personalization',
        files: []
      },
      requestTypes: [
        { translate: 'personalisation d\'une illustration', value: 'personalization' },
        { translate: 'demande d\'une illustration sur mesure', value: 'custom_made'  },
        { translate: 'demande d\'un devis', value: 'quote'  },
        { translate: 'autre', value: 'other' }
      ]
    }),
    methods: {
      sendRequest() {
        this.loading = true
        const formData = new FormData();

        for (let i = 0; i < this.form.files.length; i++) {
          formData.append('customer_request[images][]', this.form.files[i]);
        }

        formData.append('customer_request[first_name]', this.form.firstName);
        formData.append('customer_request[last_name]', this.form.lastName);
        formData.append('customer_request[phone]', this.form.phone);
        formData.append('customer_request[mail]', this.form.email);
        formData.append('customer_request[request_type]', this.form.requestType);
        formData.append('customer_request[content]', this.form.content);

        this.axios.post('/v1/customer_requests/', formData)
                  .then(() => {
                    this.loading = false;
                    this.snackbarSuccess = true;
                    this.emptyForm();
                  })
                  .catch(() => {
                    this.loading = false;
                    this.snackbarError = true;
                    this.emptyForm();
                  });
      },
      filterArray() {
        this.form.files = this.form.files.filter((valeur) => valeur !== null);
      },
      emptyForm() {
        this.form = {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          content: '',
          requestType: 'personalization',
          files: []
        }
      }
    }
  }
</script>
