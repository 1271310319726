<template>
  <v-container>
    <v-row>
      <v-dialog v-model='dialog'
                fullscreen
                persistent
                hide-overlay
                transition='dialog-bottom-transition'>
        <v-card>
          <v-toolbar dark
                     color='primary'>
            <v-toolbar-title>Paiement</v-toolbar-title>
          </v-toolbar>

          <v-card-text class='d-flex align-center justify-center'
                       style='height: 80vh;'>
            <v-progress-circular indeterminate
                                 :size='70'
                                 :width='7'
                                 v-if='loading'
                                 :active='loading' />

            <v-card v-if='!loading'>
              <v-card-title class='teal lighten-3 white--text font-weight-bold'>
                Paiement réceptionné !
              </v-card-title>

              <v-card-text class='text-center text-body-1 mt-5'>
                Votre paiement de <span class='font-weight-bold'>{{ session.amount / 100 | currencyDefault }}</span>&nbsp;
                a bien était traité
                <br>
                Votre commande est en cours de préparation !
              </v-card-text>

              <v-card-actions>
                <v-container>
                  <v-row class='ma-4 text-caption'>
                    Continuer vers le site&nbsp;
                    <a href='/'>
                      une-bonne-nouvelle.fr
                    </a>
                    .
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'CheckoutSucceed',
    data: () => ({
      loading: true,
      dialog: false,
      session: {}
    }),
    mounted() {
      if(!localStorage.getItem('cart_secure_session')) window.location = '/';

      this.dialog = true;

      this.axios.put(`v1/checkout/carts/${localStorage.getItem('cart_secure_session')}`)
                .then(response => {
                  this.session = response.data;
                  this.loading = false;

                  localStorage.removeItem('cart_secure_session');
                  localStorage.removeItem('cart_items');
                });
    }
  }
</script>
