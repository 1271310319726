<template>
  <v-app>
    <v-main class='pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0'>
      <v-container fluid
                   class='ma-0 pa-0'>
        <Header />

        <Navigation />

        <Breadcrumbs :items='breadcrumbItems' />

        <router-view />
      </v-container>
    </v-main>

    <v-footer padless>
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
  import Breadcrumbs from '../layouts/Breadcrumbs';
  import Footer      from '../layouts/Footer';
  import Header      from '../layouts/Header';
  import Navigation  from '../layouts/Navigation';

  export default {
    name: 'Home',
    components: { Breadcrumbs, Footer, Header, Navigation },
    data: () => ({
      breadcrumbItems: [{ text: 'Accueil', to: '/' }],
      calendarId: null
    }),
    watch: {
      $route() { if(this.$route.path === '/') this.breadcrumbItems = [{ text: 'Accueil', to: '/' }]; }
    },
    mounted() {
      this.calendarId = process.env.VUE_APP_CALENDAR_ID;

      this.$root.$on('updateBreadcrumbs', (items) => {
        this.breadcrumbItems = [{ text: 'Accueil', to: '/' }];
        items.map(item => this.breadcrumbItems.push(item));
      })
    }
  }
</script>

<style lang='scss' scoped>
  .theme--light.v-footer {
    background-color: transparent;
  }
</style>
