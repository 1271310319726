<template>
  <v-app>
    <v-app-bar app
               color='#BC7B5E'
               dark
               flat
               fixed
               v-if='this.$route.name !== `admin_login`'>
      <v-app-bar-nav-icon @click.stop='menuVisible = !menuVisible' />

      <div class='v-toolbar__title v-app-bar-title'>
        <router-link :to='{ name: `admin` }'
                     class='md-display-1 no-decoration'>
          Une bonne nouvelle
        </router-link>
      </div>

      <v-spacer />

      <v-btn icon
             @click='this.logout'>
        <font-awesome-icon icon='power-off' />
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model='menuVisible'
                         absolute
                         bottom
                         temporary
                         v-if='this.$route.name !== `admin_login`'>
      <NavBar />
    </v-navigation-drawer>

    <v-main app>
      <v-container fluid>
        <!-- Router view -->
        <router-view />

        <v-container v-if='this.$route.name === `admin`'>
          <v-row class='mt-10'>
            <v-col>
              <v-card>
                <v-card-title>
                  Demandes clients
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <span class='text-caption'>non lues</span>
                      <br>
                      <v-btn class='cyan darken-1 white--text text-h6'>
                        {{ unreadCustomerRequests }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <span class='text-caption'>non traitées</span>
                      <br>
                      <v-btn class='blue darken-4 white--text text-h6'>
                        {{ pendingCustomerRequests }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-spacer />
            <v-col>
              <v-card v-if='pendingOrders > 0'>
                <v-card-title>
                  Commandes
                </v-card-title>
                <v-card-text class='pl-10 text-h6'>
                  <span class='text-caption'>en attentes</span>
                  <br>
                  <v-btn class='purple white--text text-h6'>
                    {{ pendingOrders }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
  import NavBar from '@/components/admin/NavBar.vue';

  export default {
    name: 'Home',
    components: { NavBar },
    data: () => ({
      menuVisible: false,
      orders: [],
      customerRequests: []
    }),
    computed: {
      unreadCustomerRequests() {
        return this.customerRequests.filter(d => d.data.attributes.dismissed === false).length;
      },
      pendingCustomerRequests() {
        return this.customerRequests.filter(d => d.data.attributes.state === 'pending').length;
      },
      pendingOrders() {
        return this.orders.filter(d => d.data.attributes.state === 'pending').length;
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      '$route' (to, from) {
        // close menu on route change
        this.menuVisible = false;
      }
    },
    async mounted() {
      this.$root.$on('readCustomerRequest', () => this.fetchCustomerRequests());
      this.$root.$on('requestSuccess', () => this.fetchCustomerRequests());
      this.$root.$on('changeOrderState', () => this.fetchOrders());

      await this.setAuth();
      await this.fetchCustomerRequests();
      await this.fetchOrders();
    },
    methods: {
      setAuth() {
        // Get access token
        const access_token = localStorage.getItem('access_token');

        // Redirection to login page
        if(!access_token && this.$route.name !== 'admin_login')
          this.$router.replace({ name: 'admin_login' });

        // Logout if token has expired more than 24 hours ago
        const exp = this.$moment(localStorage.getItem('access_token_exp'));
        const tomorrow = this.$moment().subtract(24, 'hours');

        if(exp.isBefore(tomorrow))
          this.logout();
      },
      // Reset localStorage then Set local instances then Redirect
      logout() {
        localStorage.clear();
        this.setAuth();
      },
      fetchCustomerRequests() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/customer_requests')
              .then(response => {
                this.customerRequests = response.data;
                resolve();
              });
        });
      },
      fetchOrders() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/orders/')
              .then(response => {
                this.orders = response.data;

                resolve();
              });
        });
      }
    }
  }
</script>
