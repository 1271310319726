<template>
  <v-layout row
            class='ma-0 pa-0'>
    <v-layout row
              class='ma-0 pa-0'
              @mouseleave='closeMenu'>
      <!-- Standard menu -->
      <v-container class='d-none d-md-flex menu navigation mt-5 mb-10'>
        <v-row>
          <v-spacer />
          <v-col cols='3'
                 v-for='category in categories'
                 :key='category.id'>
            <span @mouseover='expandMenu(category.data)'
                  @click='expandMenu(category.data)'
                  class='cursor-pointer'>
              {{ category.data.attributes.title }}
            </span>
          </v-col>
          <v-col cols='3'
                 class='cursor-pointer'>
            <span @mouseover='expandInfoMenu'
                  @click='expandInfoMenu'>
              Infos et contact
            </span>
          </v-col>
          <v-col v-if='displayCart'
                 cols='1'>
            <router-link :to='`/carts`'
                         class='text-decoration-none'>
              <v-icon>mdi-cart-heart</v-icon>
            </router-link>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>

      <!-- Expanded menu categories -->
      <v-expand-transition>
        <v-row class='expanded-menu'
               v-show='expandedMenu'>
          <v-spacer />
          <v-col v-for='n in colsBefore'
                 :key='`before-` + n'
                 cols='3' />
          <v-col cols='3'>
            <v-expansion-panels focusable>
              <v-expansion-panel v-for='child in expandedCategory.attributes.children'
                                 :key='child.id'>
                <v-expansion-panel-header v-if='child.data.attributes.children.length'>
                  {{ child.data.attributes.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if='child.data.attributes.children.length'>
                  <v-list>
                    <v-list-item v-for='children in child.data.attributes.children'
                                 :key='children.id'>
                      <!-- Link to children -->
                      <router-link :to='`/categories/` + children.data.id'
                                   v-if='children.data.attributes.state === `enabled`'
                                   class='text-decoration-none'>
                        {{ children.data.attributes.title }}
                      </router-link>

                      <!-- Pending display -->
                      <span v-else
                            class='font-italic font-weight-thin blue-grey--text text--lighten-3'>
                        {{ children.data.attributes.title }}
                      </span>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
                <div class='ma-3'
                     v-if='!child.data.attributes.children.length'>
                  <!-- Link to children -->
                  <router-link :to='`/categories/` + child.data.id'
                               v-if='child.data.attributes.state === `enabled`'
                               class='text-decoration-none'>
                    {{ child.data.attributes.title }}
                  </router-link>

                  <!-- Pending display -->
                  <span v-else
                        class='font-italic font-weight-thin blue-grey--text text--lighten-3'>
                    {{ child.data.attributes.title }}
                  </span>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col v-for='n in colsAfter'
                 :key='`after-` + n'
                 cols='3' />
          <v-spacer />
        </v-row>
      </v-expand-transition>

      <!-- Expanded menu infos&contact -->
      <v-expand-transition>
        <v-row class='expanded-menu'
               style='margin-top: -40px !important;'
               v-show='expandedInfoMenu'>
          <v-spacer />
          <v-col v-for='n in this.categories.length'
                 :key='`before-ic-` + n'
                 cols='3' />
          <v-col cols='3'>
            <v-list>
              <!-- how to order -->
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/infos/how-to-order'
                               class='text-decoration-none'>
                    Comment passer une commande de faire-part
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <!-- Bonne nouvelle! -->
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/infos/good-news'
                               class='text-decoration-none'>
                    Bonne nouvelle!
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <!-- Price -->
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/infos/prices'
                               class='text-decoration-none'>
                    Tarifs
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <!-- Payments / Shipping -->
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/infos/payment-shipping'
                               class='text-decoration-none'>
                    Paiement, délais et livraison
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <!-- contact -->
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/infos/contact'
                               class='text-decoration-none'>
                    Contact
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <!-- CGV -->
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/infos/cgv'
                               class='text-decoration-none'>
                    CGV
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
          <v-spacer />
        </v-row>
      </v-expand-transition>
    </v-layout>

    <!-- Mobile menu -->
    <v-app-bar app
               elevation='0'
               class='d-md-none'>
      <v-dialog v-model='dialog'
                fullscreen
                hide-overlay
                transition='dialog-bottom-transition'>
        <template v-slot:activator='{ on }'>
          <v-app-bar-nav-icon @click.stop='dialog = !dialog'
                              v-on='on' />
        </template>

        <v-card>
          <!-- top toolbar -->
          <v-toolbar style='z-index: 1;'
                     elevation='0'>
            <v-spacer />
            <v-btn icon
                   @click.native='dialog = false'>
              <font-awesome-icon :icon='[`far`, `times-circle`]'
                                 size='lg' />
            </v-btn>
          </v-toolbar>

          <!-- Expanded menu -->
          <v-row class='pa-0 mt-10 ma-0'>
            <v-expansion-panels focusable>
              <!-- Categories -->
              <v-expansion-panel v-for='category in categories'
                                 :key='category.id'>
                <v-expansion-panel-header v-if='category.data.attributes.children.length'>
                  {{ category.data.attributes.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if='category.data.attributes.children.length'>
                  <v-expansion-panels focusable>
                    <v-expansion-panel v-for='child in category.data.attributes.children'
                                       :key='child.id'>
                      <v-expansion-panel-header v-if='child.data.attributes.children.length'>
                        {{ child.data.attributes.title }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content v-if='child.data.attributes.children.length'>
                        <v-list>
                          <v-list-item v-for='children in child.data.attributes.children'
                                       :key='children.id'>
                            <!-- Link to children -->
                            <router-link :to='`/categories/` + children.data.id'
                                         v-if='children.data.attributes.state === `enabled`'
                                         class='text-decoration-none'>
                              {{ children.data.attributes.title }}
                            </router-link>

                            <!-- Pending display -->
                            <span v-else
                                  class='font-italic font-weight-thin blue-grey--text text--lighten-3'>
                              {{ children.data.attributes.title }}
                            </span>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-content>
                      <div class='ma-3'
                           v-if='!child.data.attributes.children.length'>
                        <!-- Link to children -->
                        <router-link :to='`/categories/` + child.data.id'
                                     v-if='child.data.attributes.state === `enabled`'
                                     class='text-decoration-none'>
                          {{ child.data.attributes.title }}
                        </router-link>

                        <!-- Pending display -->
                        <span v-else
                              class='font-italic font-weight-thin blue-grey--text text--lighten-3'>
                          {{ child.data.attributes.title }}
                        </span>
                      </div>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
                <div class='ma-3'
                     v-if='!category.data.attributes.children.length'>
                  <!-- Link to children -->
                  <router-link :to='`/categories/` + category.data.id'
                               v-if='category.data.attributes.state === `enabled`'
                               class='text-decoration-none'>
                    {{ category.data.attributes.title }}
                  </router-link>

                  <!-- Pending display -->
                  <span v-else
                        class='font-italic font-weight-thin blue-grey--text text--lighten-3'>
                    {{ category.data.attributes.title }}
                  </span>
                </div>
              </v-expansion-panel>

              <!-- Infos et contact -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Infos et contact
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <!-- how to order -->
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to='/infos/how-to-order'
                                     class='text-decoration-none'>
                          Comment passer une commande de faire-part
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Bonne nouvelle! -->
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to='/infos/good-news'
                                     class='text-decoration-none'>
                          Bonne nouvelle!
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Price -->
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to='/infos/prices'
                                     class='text-decoration-none'>
                          Tarifs
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Payments / Shipping -->
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to='/infos/payment-shipping'
                                     class='text-decoration-none'>
                          Paiement, délais et livraison
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- contact -->
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to='/infos/contact'
                                     class='text-decoration-none'>
                          Contact
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- CGV -->
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to='/infos/cgv'
                                     class='text-decoration-none'>
                          CGV
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-col v-if='displayCart'
                     class='d-flex justify-center'
                     cols='12'>
                <router-link :to='`/carts`'
                             class='text-decoration-none'>
                  Voir le panier
                  <!-- <font-awesome-icon icon='shopping-cart' /> -->
                  <v-icon>mdi-cart-heart</v-icon>
                </router-link>
              </v-col>
            </v-expansion-panels>
          </v-row>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </v-layout>
</template>

<script>
  export default {
    name: 'Navigation',
    data: () => ({
      categories: [],
      dialog: false,
      expandedMenu: false,
      expandedInfoMenu: false,
      displayCart: !!localStorage.getItem('cart_secure_session'),
      colsBefore: 0,
      colsAfter: 0,
      expandedCategory: {
        attributes: {}
      }
    }),
    watch: {
      '$route' () { this.closeMenu(); }
    },
    mounted() {
      this.$root.$on('openCart', () => {
        this.displayCart = !!localStorage.getItem('cart_secure_session');
      });
      this.fetchCategories();
    },
    methods: {
      fetchCategories() {
        this.axios.get('/v1/categories')
                  .then(response => this.categories = response.data);
      },
      expandMenu(category) {
        this.closeMenu();

        this.expandedCategory = category;

        const position = this.categories.findIndex(element => element.data.id === category.id);
        const length = this.categories.length;

        this.colsBefore = position;
        this.colsAfter = length - position;

        this.expandedMenu = true;
      },
      expandInfoMenu() {
        this.expandedMenu = false;
        this.expandedCategory = { attributes: {} };
        this.colsBefore = 0;
        this.colsAfter = 0;

        this.expandedInfoMenu = true;
      },
      closeMenu() {
        this.expandedInfoMenu = false;
        this.expandedMenu = false;
        this.expandedCategory = { attributes: {} };
        this.colsBefore = 0;
        this.colsAfter = 0;

        this.dialog = false;
      }
    }
  }
</script>

<style lang='scss' scoped>
  .navigation {
    padding: 15px;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .menu {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }

  .theme--light.v-expansion-panels .v-expansion-panel, v-expansion-panel-header {
    background-color: #fdfdfd !important;
  }

  .v-item-group, .v-expansion-panel {
    position: inherit !important;
  }

  .v-expansion-panels {
    z-index: 0 !important;
  }

  .expanded-menu {
    width: 100%;
    background-color: #fdfdfd !important;
    margin-top: -40px;
    .v-list {
      background-color: #fdfdfd !important;
    }
  }

  a {
    color: #363434 !important;
  }

  .v-application .white {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: transparent !important;
  }
</style>
