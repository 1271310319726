<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <v-btn elevation='1'
                 class='white'
                 icon
                 @click='backwardNavigation'>
            <font-awesome-icon icon='chevron-left' />
          </v-btn>

          <span class='ml-2 mt-5 text-h6'>Commandes</span>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row dense>
        <!-- Customer informations -->
        <v-col cols='6'>
          <v-card color='#385F73'
                  class='customer-informations'
                  height='150'
                  dark>
            <v-card-title class='text-h5'>
              {{ order.data.attributes.firstname }} {{ order.data.attributes.lastname }}
            </v-card-title>

            <v-card-subtitle>{{ order.data.attributes.email }} - {{ order.data.attributes.phone }}</v-card-subtitle>

            <v-card-actions>
              {{ order.data.attributes.address }}
              <br>
              {{ order.data.attributes.zipcode }} {{ order.data.attributes.city }}
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- Order amount -->
        <v-col cols='3'>
          <v-card color='#45c7ae'
                  height='150'
                  dark>
            <v-card-text style='height: 100%;'>
              <v-row>
                <v-col>
                  <v-chip class='white--text green darken-1'>
                    Montant de la commande
                  </v-chip>
                </v-col>
              </v-row>

              <v-row class='d-flex align-center mt-10'>
                <v-col>
                  <span class='price'>
                    {{ order.data.attributes.total_amount }}
                  </span>
                </v-col>
                <v-col>
                  <v-tooltip bottom>
                    <template v-slot:activator='{ on, attrs }'>
                      <v-icon class='green--text text--darken-1'
                              v-if='order.data.attributes.payment_state === `paid`'
                              style='font-size: 2rem; float: right;'
                              v-bind='attrs'
                              v-on='on'>
                        mdi-currency-eur
                      </v-icon>

                      <v-icon class='red--text text--darken-1'
                              v-else
                              style='font-size: 2rem; float: right;'
                              v-bind='attrs'
                              v-on='on'>
                        mdi-alert-circle
                      </v-icon>
                    </template>

                    <span>{{ order.data.attributes.translated_payment_state }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- State -->
        <v-col cols='3'>
          <v-card color='#bc7b5e'
                  height='150'
                  dark>
            <v-card-title>
              <v-chip class='white--text blue accent-4'>
                Statut
              </v-chip>
            </v-card-title>

            <v-card-text>
              <template>
                <div class='text-right mt-10'
                     v-if='order.data.attributes.state'>
                  <v-menu v-model='stateMenu'
                          :close-on-content-click='false'
                          :nudge-width='200'
                          offset-y>
                    <template v-slot:activator='{ on, attrs }'>
                      <v-btn :color='states.filter(s => s.value === order.data.attributes.state)[0].color'
                             dark
                             v-bind='attrs'
                             v-on='on'>
                        {{ order.data.attributes.translated_state }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-text>
                        <v-select :items='states'
                                  v-model='order.data.attributes.state'
                                  item-text='translate'
                                  item-value='value'
                                  hide-details='auto'
                                  @change='updateState'
                                  label='État' />
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn text
                               @click='stateMenu = false'>
                          Annuler
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if='order.data.attributes.items'>
      <v-row dense>
        <v-col cols='4'
               v-for='item in order.data.attributes.items'
               :key='item.data.id'>
          <v-card dark>
            <v-card-title class='text-h5'>
              {{ item.data.attributes.variant.data.attributes.reference }}
            </v-card-title>

            <v-card-subtitle>{{ item.data.attributes.variant.data.attributes.variant_title }}</v-card-subtitle>

            <v-card-text>
              <v-img :lazy-src='item.data.attributes.variant.data.attributes.lazy_images_urls[0]'
                     :src='item.data.attributes.variant.data.attributes.images_urls[0]'
                     class='mb-5'
                     aspect-ratio='1'
                     contain />
              <hr>
              <span class='text-caption mt-5'>Quantité : {{ item.data.attributes.quantity }}</span>
              <br>
              <span class='text-caption'>Montant : {{ item.data.attributes.product_amount | currency }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'AdminOrder',
    data: () => ({
      stateMenu: false,
      states: [
        { translate: 'En attente', value: 'pending', color: 'purple' },
        { translate: 'Envoyée', value: 'sent', color: 'teal' },
        { translate: 'Annulée', value: 'canceled', color: 'red' },
        { translate: 'En cours', value: 'wip', color: 'blue' },
        { translate: 'Abandonnée', value: 'aborted', color: 'red' },
        { translate: 'Fini', value: 'completed', color: 'green' }
      ],
      order: {
        data: {
          attributes: {}
        }
      }
    }),
    async mounted() {
      await this.fetchOrder();
    },
    methods: {
      fetchOrder() {
        return new Promise(resolve => {
          this.axios.get(`/v1/admin/orders/${this.$route.params.id}`)
                    .then(response => {
                      this.order = response.data;

                      resolve();
                    });
        });
      },
      updateState() {
        const params = { order: { state: this.order.data.attributes.state } }
        this.stateMenu = false;

        this.axios.put(`v1/admin/orders/${this.$route.params.id}`, params)
                  .then(response => {
                    this.order = response.data;

                    this.$root.$emit('changeOrderState');
                  });
      },
      backwardNavigation() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style lang='scss' scoped>
  .customer-informations {
    font-family: Roboto,sans-serif !important;
  }

  .price {
    font-size: 2.5rem;
    font-family: Roboto,sans-serif !important;
    text-align: center;
  }
</style>
