// Modules
import Vue          from 'vue';
import VueRouter    from 'vue-router';

// Use
Vue.use(VueRouter);

// Import Main Components
import Home            from '../components/main/home/Home';
import HomePage        from '../components/main/home/HomePage';
import Cart            from '../components/main/cart/Cart';
import Categories      from '../components/main/categories/Categories';
import Infos           from '../components/main/infos/Infos';
import Product         from '../components/main/products/Product';
// import Checkout        from '../components/main/checkout/Checkout';
import CheckoutFailed  from '../components/main/checkout/CheckoutFailed';
import CheckoutSucceed from '../components/main/checkout/CheckoutSucceed';
// Import Admin Components
import Admin                 from '@/components/admin/Home';
import AdminCategories       from '@/components/admin/categories/AdminCategories';
import AdminCustomerRequests from '@/components/admin/customer_requests/CustomerRequests';
import AdminDiscounts        from '@/components/admin/discounts/AdminDiscounts';
import AdminLogin            from '@/components/admin/Login';
import AdminOrders           from '@/components/admin/orders/AdminOrders';
import AdminOrder            from '@/components/admin/orders/AdminOrder';
import AdminProducts         from '@/components/admin/products/AdminProducts';
import AdminShippings        from '@/components/admin/shippings/AdminShippings';

// Router
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      name: 'catchAll',
      component: Home
    },
    {
      path: '/',
      component: Home,
      children: [
        { path: '', name: 'home', component: HomePage },
        { path: 'carts',  name: 'carts', component: Cart },
        { path: 'categories/:id',  name: 'categories', component: Categories },
        { path: 'infos/:type',  name: 'infos', component: Infos },
        { path: 'products/:id',  name: 'product', component: Product },
        // { path: 'checkout',  name: 'checkout', component: Checkout },
        { path: 'checkout_failed/:type',  name: 'checkout_failed', component: CheckoutFailed },
        { path: 'checkout/:type/:session',  name: 'checkout_succeed', component: CheckoutSucceed },
      ]
    },
    { path: '/admin',
      name: 'admin',
      component: Admin,
      children: [
        { path: 'categories',        name: 'admin_categories',        component: AdminCategories },
        { path: 'customer_requests', name: 'admin_customer_requests', component: AdminCustomerRequests },
        { path: 'discounts',         name: 'admin_discounts',         component: AdminDiscounts  },
        { path: 'orders',            name: 'admin_orders',            component: AdminOrders     },
        { path: 'orders/:id',        name: 'admin_order',             component: AdminOrder      },
        { path: 'products',          name: 'admin_products',          component: AdminProducts   },
        { path: 'shippings',         name: 'admin_shippings',         component: AdminShippings  },
        { path: 'login',             name: 'admin_login',             component: AdminLogin      },
      ]
    },
  ]
});

export default router;
