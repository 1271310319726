<template>
  <v-card tile
          class='mx-auto my-16'
          elevation='5'
          max-width='800'
          :loading='loading'>
    <template slot='progress'>
      <v-progress-linear color='deep-purple'
                         height='5'
                         indeterminate />
    </template>

    <div class='pa-6'>
      <v-card-title>
        <h2>Une bonne nouvelle</h2>
      </v-card-title>
      <v-card-subtitle>
        <h3>Administration</h3>
      </v-card-subtitle>

      <v-form @submit.prevent='auth'>
        <v-row>
          <v-col>
            <v-text-field v-model='login.email'
                          autofocus
                          required
                          :validate-on-blur='loading'
                          label='Addresse mail' />
          </v-col>

          <v-col>
            <v-text-field v-model='login.password'
                          :validate-on-blur='loading'
                          type='password'
                          required
                          label='Mot de passe' />
          </v-col>
        </v-row>

        <v-row>
          <v-spacer />
          <v-col cols='2'>
            <v-btn x-small
                   class='mr-4 p indigo lighten-1 white--text'
                   type='submit'>
              Connexion
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'Login',
    data: () => ({
      loading: false,
      login: {
        email: '',
        password: ''
      }
    }),
    methods: {
      auth() {
        this.loading = true;

        let params = { email: this.login.email, password: this.login.password }
        this.axios.post('/authenticate', params)
                  .then(response => {
                    // Set localStorage
                    localStorage.setItem('access_token', response.data.token);
                    localStorage.setItem('access_token_exp', response.data.exp);
                    window.location = '/admin';
                  })
                  .catch(error => {
                    console.log(error)
                    this.login.password = '';
                    this.loading = false;
                  });
      }
    }
  }
</script>
