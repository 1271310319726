<template>
  <v-container>
    <v-row>
      <v-col cols='12'
             md='6'
             class='scrollable-content'>
        <!-- Items -->
        <CartItems :items='items' />
      </v-col>
      <v-col cols='12'
             md='6'>
        <v-row>
          <v-col cols='10'
                 offset='1'>
            <span class='text-h5'>
              Finalisation de la commande :
            </span>
          </v-col>
        </v-row>
        <!-- Order inputs -->
        <OrderInputs :cart='cart' />
        <!-- Validate Order -->
        <OrderValidation :cart='cart' />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CartItems from './components/CartItems';
  import OrderInputs from './components/OrderInputs';
  import OrderValidation from './components/OrderValidation';

  export default {
    name: 'Cart',
    components: { CartItems, OrderInputs, OrderValidation },
    data: () => ({
      cart: { attributes: {} },
      items: []
    }),
    mounted() {
      this.$root.$on('deleteItem', (item) => this.deleteItem(item));
      this.$root.$on('updateQuantity', (item) => this.updateQuantity(item));
      this.$root.$on('applyPromo', (code) => this.applyPromo(code));

      this.setBreadcrumbs();
      this.fetchCart();
    },
    methods: {
      fetchCart() {
        this.axios.get(`/v1/carts/${localStorage.getItem('cart_secure_session')}`)
                  .then(response => {
                    this.cart = response.data.data;
                    this.cart.attributes.items.map(item => this.items.push(item.data.attributes));
                  });
      },
      setBreadcrumbs() {
        const items = [{ text: 'Panier', to: '/carts' }];

        this.$root.$emit('updateBreadcrumbs', items);
      },
      deleteItem(item) {
        this.axios.delete(`/v1/cart_items/${item.id}`)
                  .then(() => {
                    this.items = this.items.filter(i => i.id !== item.id);

                    if(this.items.length === 0) {
                      localStorage.removeItem('cart_secure_session');
                      localStorage.removeItem('cart_items');
                      this.$root.$emit('openCart');
                      window.location = '/';
                    } else {
                      const items = this.items.map(item => item.variant.data.id)

                      localStorage.removeItem('cart_items');
                      localStorage.setItem('cart_items', items.join('/'));
                    }
                  });
      },
      updateQuantity(item) {
        const params = {
          cart_item: { quantity: item.quantity }
        };

        this.axios.put(`/v1/cart_items/${item.id}`, params)
                  .then(response => this.cart = response.data.data);
      },
      applyPromo(code) {
        const params = { code: code };

        this.axios.put(`v1/apply_promos/${localStorage.getItem('cart_secure_session')}`, params)
                  .then(response => this.cart = response.data.data);
      }
    }
  }
</script>

<style scoped>
  .scrollable-content {
    overflow: auto;
    height: 100vh;
  }
</style>
