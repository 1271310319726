<template>
  <v-container>
    <!-- Title -->
    <v-row class='mb-5 mt-5'>
      <v-col cols='3'>
        <span class='text-h4'>Demandes clients</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols='12'>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th />
                <th />
                <th>Nom</th>
                <th>Date</th>
                <th>Demande</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='item in customerRequests'
                  :key='item.data.id'
                  class='cursor-pointer'
                  @click='openDialog(item.data)'>
                <td>
                  <v-badge color='blue lighten-2'
                           v-if='!item.data.attributes.dismissed'
                           large
                           dot />
                </td>
                <td>
                  <v-btn class='cyan lighten-1 white--text'
                         v-if='item.data.attributes.state === `pending`'
                         icon
                         small>
                    <v-icon>mdi-account-clock</v-icon>
                  </v-btn>
                  <v-btn class='green lighten-1 white--text'
                         v-if='item.data.attributes.state === `answered`'
                         icon
                         small>
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                  <v-btn class='red lighten-1 white--text'
                         v-if='item.data.attributes.state === `deleted`'
                         icon
                         small>
                    <v-icon>mdi-close-octagon-outline</v-icon>
                  </v-btn>
                </td>
                <td title='Noms'
                    class='font-weight-bold'>
                  {{ item.data.attributes.first_name }} {{ item.data.attributes.last_name }}
                </td>
                <td title='Date de création de la demande'
                    class='font-weight-bold'>
                  {{ item.data.attributes.created_at | luxon }}
                </td>
                <td title='Demande'>
                  {{ truncateText(item.data.attributes.content, 15) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <!-- Snackbar success -->
    <v-snackbar v-model='snackbarSuccess'
                :timeout='5000'
                right
                color='green lighten-2'
                elevation='24'>
      Mis à jour !
    </v-snackbar>

    <!-- Snackbar Error -->
    <v-snackbar v-model='snackbarError'
                :timeout='5000'
                right
                color='red accent-4'
                elevation='24'>
      Une erreur est survenue
    </v-snackbar>

    <!-- Modal new category -->
    <v-dialog transition='dialog-bottom-transition'
              v-model='dialog'
              scrollable
              max-width='1000'>
      <CustomerRequest :customer-request='dialogData' />
    </v-dialog>
  </v-container>
</template>

<script>
  import CustomerRequest from './CustomerRequest';

  export default {
    name: 'CustomerRequests',
    components: { CustomerRequest },
    data: () => ({
      customerRequests: [],
      snackbarSuccess: false,
      snackbarError: false,
      dialog: false,
      dialogData: {}
    }),
    async mounted() {
      this.$root.$on('requestSuccess', (id, state) => this.updateStateSuccess(id, state));
      this.$root.$on('requestError', () => this.updateStateError());

      await this.fetchCustomerRequests();
    },
    methods: {
      fetchCustomerRequests() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/customer_requests')
                    .then(response => {
                      this.customerRequests = response.data;
                      resolve();
                    });
        });
      },
      truncateText(text, length) {
        return text.length > length ? text.substring(0, length) + "..." : text;
      },
      openDialog(data) {
        this.dialog     = !this.dialog;
        this.dialogData = data.attributes;

        if(!data.attributes.dismissed) {
          const params = { customer_request: { dismissed: true } };

          this.axios.put(`/v1/admin/customer_requests/${data.id}`, params)
                    .then(() => {
                      data.attributes.dismissed = true;
                      this.$root.$emit('readCustomerRequest');
                    });
        }
      },
      updateStateSuccess(id, state) {
        this.customerRequests.find(d => d.data.attributes.id === id).data.attributes.state = state;
        this.snackbarSuccess = true;
        this.dialog = false;
      },
      updateStateError() {
        this.snackbarError = true;
      }
    }
  }
</script>

<style lang='scss' scoped>
  .cursor-pointer {
    cursor: pointer !important;
  }
</style>
