<template>
  <div>
    <h2>Livraisons</h2>
  </div>
</template>

<script>
  export default {
    name: 'AdminShippings'
  }
</script>
