<template>
  <div>
    <v-window-item v-for='(item, index) in items'
                   :key='index'>
      <v-card flat>
        <v-card-text>
          <v-row class='mb-4'
                 align='center'>
            <v-col>
              <p class='text-h4 ml-4 ubn-color text-center'>
                {{ item.name }}
              </p>
            </v-col>
          </v-row>

          <HowToOrder v-if='item.href === `how-to-order`' />
          <GoodNews v-else-if='item.href === `good-news`' />
          <Prices v-else-if='item.href === `prices`' />
          <PaymentShipping v-else-if='item.href === `payment-shipping`' />
          <Contact v-else-if='item.href === `contact`' />
          <TOS v-else-if='item.href === `cgv`' />
        </v-card-text>
      </v-card>
    </v-window-item>
  </div>
</template>

<script>
  import HowToOrder from './HowToOrder';
  import GoodNews from './GoodNews';
  import Prices from './Prices';
  import PaymentShipping from './PaymentShipping';
  import Contact from './Contact';
  import TOS from './TOS';

  export default {
    name: 'WindowItems',
    components: { HowToOrder, GoodNews, Prices, PaymentShipping, Contact, TOS },
    props: {
      items: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
