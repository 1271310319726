<template>
  <div>
    <v-img :src='require(`@/assets/infos/order_timeline.png`)' />

    <ol>
      <li class='text-wrap'>
        <span class='orange--text text--lighten-1'>Un modèle vous plaît ?</span>
        <br>
        Vous pouvez cliquer sur l’onglet “choisir ce modèle”
        qui vous conduira vers un formulaire de personnalisation.
      </li>

      <li class='text-wrap'>
        <span class='orange--text text--lighten-1'>La personnalisation :</span>
        <br>
        Complétez les informations demandées dans le formulaire, validez
        ces renseignements et notre maquettiste s’occupe  de tout;
        <br>
        ce service est inclus dans le prix.
        A partir de vos indications nous réaliserons une ou plusieurs maquettes, c’est à dire une mise en page des textes sur votre création.
        <br>
        <br>
        <span class='blue-grey--text text--lighten-1'>
          Si vous commandez plusieurs modèles sur notre site (ex : faire-part,
          invitations et marque-places), les cartes seront alors livrées en une commande pour les demandes faites
          dans les 8 jours. (ou plus à votre demande)
        </span>
      </li>

      <li class='text-wrap'>
        <span class='orange--text text--lighten-1'>La maquette :</span>
        <br>
        Après la validation de ces renseignements, vous recevrez un e-mail de
        confirmation, puis sous 48h ouvrées, un e-mail comprenant votre maquette
        (éventuellement plusieurs propositions de maquettes) ainsi que le tarif et
        le détail de votre commande.
        <br>
        Si celle-ci vous convient, vous pouvez la valider, si vous souhaitez la
        modifier, les échanges se feront  par e-mail jusqu’à votre satisfaction.
        <br>
        Il est possible que plusieurs propositions de maquettes
        vous soient faites, vous devrez alors faire un choix.
        <br>
        Attention, les couleurs des modèles à l’écran peuvent être
        légèrement différentes du modèle imprimé.
      </li>

      <li class='text-wrap'>
        <span class='orange--text text--lighten-1'>La validation du bon à tirer :</span>
        <br>
        Dans tous les cas, le règlement de votre commande se fait après votre
        validation du bon à tirer comprenant la maquette finale;
      </li>

      <li class='text-wrap'>
        <span class='orange--text text--lighten-1'>L’impression :</span>
        <br>
        L'impression de votre commande sera alors lancée et vous serez
        régulièrement informés de l’évolution de celle-ci (3 à 8 jours ouvrés).
      </li>

      <li class='text-wrap'>
        <span class='orange--text text--lighten-1'>La livraison :</span>
        <br>
        Lorsque votre commande sera prête vous recevrez un e-mail vous informant
        de son expédition (2 jours ouvrés).
      </li>
    </ol>

    <v-row class='mt-5'>
      <v-spacer />
      <v-col cols='2'>
        <v-img :src='require(`@/assets/infos/heart_flying_envelope.png`)' />
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
  export default { name: 'HowToOrder' }
</script>
