<template>
  <v-row class='mt-10'>
    <v-spacer />
    <v-col cols='10'>
      <v-row>
        <v-col cols='6'>
          <v-text-field label='Nom'
                        v-model='cart.attributes.lastname'
                        :error-messages='errors.lastname'
                        :success='!!(success && cart.attributes.lastname)'
                        :success-messages='[]'
                        required
                        hint='obligatoire'
                        :persistent-hint='(cart.attributes.lastname || ``).length === 0'
                        :rules='rules.lastname'
                        @change='updateCart' />
        </v-col>
        <v-col cols='6'>
          <v-text-field label='Prénom'
                        v-model='cart.attributes.firstname'
                        :error-messages='errors.firstname'
                        :success='!!(success && cart.attributes.firstname)'
                        :success-messages='[]'
                        required
                        hint='obligatoire'
                        :persistent-hint='(cart.attributes.firstname || ``).length === 0'
                        :rules='rules.firstname'
                        @change='updateCart' />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='6'>
          <v-text-field label='Email'
                        v-model='cart.attributes.email'
                        :error-messages='errors.phone'
                        :success='!!(success && cart.attributes.email)'
                        :success-messages='[]'
                        required
                        hint='obligatoire'
                        :persistent-hint='(cart.attributes.email || ``).length === 0'
                        :rules='rules.email'
                        @change='updateCart' />
        </v-col>
        <v-col cols='6'>
          <v-text-field label='N° de tél'
                        v-model='cart.attributes.phone'
                        :error-messages='errors.phone'
                        :success='!!(success && cart.attributes.phone)'
                        :success-messages='[]'
                        required
                        hint='obligatoire'
                        :persistent-hint='(cart.attributes.phone || ``).length === 0'
                        :rules='rules.phone'
                        @change='updateCart' />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label='Adresse'
                        v-model='cart.attributes.address'
                        :error-messages='errors.address'
                        :success='!!(success && cart.attributes.address)'
                        :success-messages='[]'
                        required
                        hint='obligatoire'
                        :persistent-hint='(cart.attributes.address || ``).length === 0'
                        :rules='rules.address'
                        @change='updateCart' />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='6'>
          <v-text-field label='Ville'
                        v-model='cart.attributes.city'
                        :error-messages='errors.city'
                        :success='!!(success && cart.attributes.city)'
                        :success-messages='[]'
                        required
                        hint='obligatoire'
                        :persistent-hint='(cart.attributes.city || ``).length === 0'
                        :rules='rules.city'
                        @change='updateCart' />
        </v-col>
        <v-col cols='6'>
          <v-text-field label='Code postal'
                        v-model='cart.attributes.zipcode'
                        :error-messages='errors.zipcode'
                        :success='!!(success && cart.attributes.zipcode)'
                        :success-messages='[]'
                        required
                        hint='obligatoire'
                        :persistent-hint='(cart.attributes.zipcode || ``).length === 0'
                        :rules='rules.zipcode'
                        @change='updateCart' />
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
  export default {
    name: 'OrderInputs',
    props: {
      cart: { type: Object, default: () => {} }
    },
    data: () => ({
      success: true,
      errors: {
        firstname: [],
        lastname: [],
        phone: [],
        email: [],
        address: [],
        city: [],
        zipcode: []
      },
      rules: {
        firstname: [val => (val || '').length > 0 || 'obligatoire'],
        lastname: [val => (val || '').length > 0 || 'obligatoire'],
        email: [val => (val || '').length > 0 || 'obligatoire'],
        phone: [val => (val || '').length === 10 || 'un numéro de téléphone est attendu'],
        address: [val => (val || '').length > 0 || 'obligatoire'],
        city: [val => (val || '').length > 0 || 'obligatoire'],
        zipcode: [val => [4, 5].includes((val || '').length) || 'un code postal est attendu']
      },
    }),
    methods: {
      updateCart() {
        const params = {
          cart: {
            firstname: this.cart.attributes.firstname,
            lastname: this.cart.attributes.lastname,
            email: this.cart.attributes.email,
            phone: this.cart.attributes.phone,
            address: this.cart.attributes.address,
            city: this.cart.attributes.city,
            zipcode: this.cart.attributes.zipcode,
          }
        }

        this.axios.put(`/v1/carts/${localStorage.getItem('cart_secure_session')}`, params)
                  .then(() => {
                    this.errors = { firstname: [], lastname: [], phone: [], email: [], address: [], city: [], zipcode: [] };
                    this.success = true;
                  })
                  .catch(error => {
                    const data = error.response.data;

                    if(data.lastname) this.errors.lastname = data.lastname; else this.errors.lastname = [];
                    if(data.firstname) this.errors.firstname = data.firstname; else this.errors.firstname = [];
                    if(data.email) {
                      this.errors.email = `${this.cart.attributes.email} ${data.email}`;
                      this.cart.attributes.email = '';
                    } else {
                      this.errors.email = [];
                    }
                    if(data.phone) {
                      this.errors.phone = `${this.cart.attributes.phone} ${data.phone}`;
                      this.cart.attributes.phone = '';
                    } else {
                      this.errors.phone = [];
                    }
                    if(data.address) this.errors.address = data.address; else this.errors.address = [];
                    if(data.city) this.errors.city = data.city; else this.errors.city = [];
                    if(data.zipcode) this.errors.zipcode = data.zipcode; else this.errors.zipcode = [];
                  });
      }
    }
  }
</script>
