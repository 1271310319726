// Import Vue and FontAwesome libs
import Vue                 from 'vue';
import { library }         from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import solid icons
import {
  faArrowCircleRight,
  faArrowRight,
  faBackward,
  faBan,
  faBars,
  faBook,
  faBox,
  faBuilding,
  faChevronDown,
  faCheckCircle,
  faCheck,
  faChevronLeft,
  faChevronUp,
  faCircle as fasCircle,
  faDollarSign,
  faEuroSign,
  faExchangeAlt,
  faEye,
  faFileInvoice,
  faHandHoldingUsd,
  faHome,
  faInfoCircle,
  faLevelDownAlt,
  faMinus,
  faMoneyCheckAlt,
  faPencilAlt,
  faPlus,
  faPowerOff,
  faPrint,
  faRedo,
  faShoppingCart,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

// Import regular icons
import {
  faCircle as farCircle,
  faCopy,
  faCreditCard,
  faEnvelope,
  faFileAlt,
  faIdCard,
  faTrashAlt,
  faTimesCircle,
  faUser
} from '@fortawesome/free-regular-svg-icons';

library.add(
  // Solid icons
  faArrowCircleRight,
  faArrowRight,
  faBackward,
  faBan,
  faBars,
  faBook,
  faBox,
  faBuilding,
  faChevronDown,
  faCheckCircle,
  faCheck,
  faChevronLeft,
  faChevronUp,
  fasCircle,
  faDollarSign,
  faEuroSign,
  faExchangeAlt,
  faEye,
  faFileInvoice,
  faHandHoldingUsd,
  faHome,
  faInfoCircle,
  faLevelDownAlt,
  faMinus,
  faMoneyCheckAlt,
  faPencilAlt,
  faPlus,
  faPowerOff,
  faPrint,
  faRedo,
  faShoppingCart,
  faTrash,
  // Regular icons
  farCircle,
  faCopy,
  faCreditCard,
  faEnvelope,
  faFileAlt,
  faIdCard,
  faTrashAlt,
  faTimesCircle,
  faUser
);

// Import Font awesome component
Vue.component('font-awesome-icon', FontAwesomeIcon);
