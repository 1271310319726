// Import modules
import Vue from 'vue';

// Plugins
require('@/plugins/axios');
require('@/plugins/filters');
require('@/plugins/font-awesome');
require('@/plugins/luxon');
require('@/plugins/moment');
require('@/plugins/plugins');
import router  from '@/plugins/router';
import vuetify from '@/plugins/vuetify';

// Vue config
Vue.config.devtools      = process.env.VUE_APP_USE_DEVTOOLS;
Vue.config.productionTip = process.env.VUE_APP_USE_PRODUCTION_TIPS;

// Import App Component
import App from '@/App.vue';

// Initialize Vue
new Vue({
  el: '#app',
  components: { App },
  router,
  vuetify,
  render: h => h(App)
});
