<template>
  <v-container fluid>
    <v-row>
      <v-col cols='10'
             offset='1'>
        <span class='text-h5'>
          Résumé des produits :
        </span>
      </v-col>
    </v-row>
    <v-row v-for='cartItem in items'
           :key='`main-display-${cartItem.id}`'>
      <v-spacer />
      <v-col cols='10'>
        <v-card>
          <v-card-title>
            <router-link :to='`/products/${cartItem.variant.data.id}`'
                         class='text-decoration-none'>
              {{ cartItem.variant.data.attributes.variant_title }}
            </router-link>
            <v-spacer />
            <v-btn icon
                   elevation='1'
                   @click='deleteItem(cartItem)'>
              <font-awesome-icon icon='trash' />
            </v-btn>
          </v-card-title>
          <v-card-text>
            <!-- Image -->
            <v-row>
              <v-col class='pa-10'>
                <v-img :lazy-src='cartItem.variant.data.attributes.lazy_images_urls[0]'
                       :src='cartItem.variant.data.attributes.images_urls[0]' />
              </v-col>
            </v-row>
            <v-divider />
            <!-- Price -->
            <v-row class='mt-5'>
              <v-col>
                <p class='font-weight-thin black--text text-body-2'>
                  Prix unitaire {{ unitPrice(cartItem.variant.data.attributes, cartItem.quantity) | currencyDefault }}
                </p>
                <p class='font-weight-bold green--text text-body-1'>
                  Prix {{ price(cartItem.variant.data.attributes, cartItem.quantity) | currencyDefault }}
                </p>
              </v-col>
            </v-row>
            <!-- Qty -->
            <v-row class='mt-5'>
              <v-col>
                <v-select :items='availableQuantities(cartItem.variant, cartItem)'
                          v-model='cartItem.quantity'
                          @change='updateQuantity(cartItem)'
                          hide-details='auto'>
                  <template v-slot:item='{ item }'>
                    <v-row class='d-flex justify-center align-center show-labels'>
                      <v-col>
                        {{ item }}
                      </v-col>
                      <v-col>
                        <span class='text-caption font-weight-thin blue--text'>prix unitaire</span>
                        {{ unitPrice(cartItem.variant.data.attributes, item) | currencyDefault }}
                      </v-col>
                      <v-col>
                        <span class='text-caption font-weight-thin blue--text'>prix</span>
                        {{ price(cartItem.variant.data.attributes, item) | currencyDefault }}
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'CartItem',
    data: () => ({
      limitedQty: process.env.VUE_APP_LIMITED_QUANTITY.split(',').map(i => Number(i))
    }),
    props: { items: { type: Array, default: () => [] } },
    methods: {
      availableQuantities(variant, item) {
        const qty = variant.data.attributes.quantity;
        const array = [];

        if(!qty || qty.disabled) { return []; }

        let to = qty.to;

        if(this.limitedQty.includes(qty.id)) {
          to -= variant.data.attributes.ordered_quantity

          if(item.quantity > to) {
            item.quantity = to;

            this.updateQuantity(item)
          }
        }

        for (let x = qty.from; x <= to; x = (x + qty.incr)) {
          array.push(x)
        }

        return array;
      },
      unitPrice(item, qty) {
        const price = item.price;
        let value = price.value;

        if(price.price_limit && price.price_limit < qty) qty = price.price_limit;

        if(price.price_type === 'formula') value = value.replaceAll('{qty}', qty);

        return Math.round(eval(value) * 100) / 100;
      },
      price(item, qty) {
        return this.unitPrice(item, qty) * qty;
      },
      deleteItem(item) { this.$root.$emit('deleteItem', item) },
      updateQuantity(item) { this.$root.$emit('updateQuantity', item) }
    }
  }
</script>


<style lang='scss' scoped>
  .show-labels {
    span {
      display: none;
    }
  }

  .show-labels:hover {
    span {
      display: inline-block;
    }
  }
</style>
