<template>
  <v-row class='ma-0 pa-0'>
    <v-col cols='12'>
      <router-link to='/'>
        <div class='header' />
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
  export default { name: 'Header' }
</script>

<style scoped>
  .header {
    width: 100%;
    height: 300px;
    background-image: url('../../../assets/header.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
