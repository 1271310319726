// import modules
import Vue      from 'vue';
import axios    from 'axios';
import VueAxios from 'vue-axios';
// import Qs       from 'qs';

// use axios with Vue
Vue.use(VueAxios, axios)

// Set default url
Vue.axios.defaults.baseURL = process.env.VUE_APP_CRM_UBN_URL;

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

// let token = document.head.querySelector('meta[name="csrf-token"]');
//
// if (token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
// } else {
//     console.error('CSRF token not found');
// }


// function Sweetalert to handle expired session
// function session_expired() {
//   Vue.$swal({
//     title: 'Session expired',
//     text: 'Votre session a expirée. Souhaitez-vous être redirigé vers la page de connexion ?',
//     icon: 'warning',
//     buttons: {
//       cancel: 'Rester sur la page',
//       confirm: {text: 'Oui', value: true, className: 'md-raised md-primary'}
//     }
//   })
//   .then((value) => {
//     if (value) {
//       localStorage.setItem('access_token', '');
//       localStorage.setItem('access_token_exp', '');
//       window.location = '/login';
//     }
//   });
// }

// #################################################
// ##                AXIOS REQUEST                ##
// #################################################

// Vue.axios.interceptors.request.use(function(config) {
//   // Format nested params correctly
//   config.paramsSerializer = params => {
//     return Qs.stringify(params, {
//       arrayFormat: 'brackets',
//       encode: false
//     });
//   };
//
//   // Send access_token at every request in header and check access_token expiration time
//   const token = localStorage.getItem('access_token');
//   const exp   = localStorage.getItem('access_token_exp');
//
//   if (exp && new Date(exp) <= new Date()) {
//    session_expired();
//   } else if (token) {
//     config.headers.Authorization = token;
//   }
//   return config;
// }, function(err) {
//     return Promise.reject(err);
// });


// ###################################################
// ##                 AXIOS RESPONSE                ##
// ###################################################

// Vue.axios.interceptors.response.use(function(response) {
//   // Refresh token on response
//   const refresh_token = response.headers.refresh_token;
//   if (refresh_token) {
//     localStorage.setItem('access_token',     refresh_token);
//     localStorage.setItem('access_token_exp', response.headers.refresh_exp);
//   }
//   return response;
// },
// // HANDLE RESPONSE ERROR
// function (error) {
//   switch (error.response.status) {
//
//     // Intercept Error 401
//     case 401:
//       if(localStorage.getItem('access_token')) {
//         session_expired();
//       } else {
//         return Promise.reject('Identifiants incorrect');
//       }
//       break;
//
//     // Intercept Error 403
//     case 403:
//       window.location = '/';
//       break;
//
//     // Intercept Error 500
//     case 500:
//       Vue.$swal({
//         title: 'Something went wrong',
//         text: 'Une erreur est survenue. Veuillez contacter le service informatique.',
//         icon: 'error',
//         buttons: false
//       })
//       break;
//
//     default:
//       return Promise.reject(error);
//   }
// });
